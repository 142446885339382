import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import FuelFaqProbAccordion from "./fuelfaqproblemsaccordions";

function FuelExpenses() {

  return (
    <div className="px-8 pt-12 pb-8 md:p-8 lg:p-8">

      <h2 className="max-w-3xl mx-auto mt-0 md:mt-8 text-2xl font-bold text-blueone  md:text-center md:text-4xl md:leading-[2.75rem]">
        <span className="text-primary">Reduce fuel expense & theft</span> via Fuel Level Monitoring System for heavy vehicles
      </h2>

      <div className="max-w-5xl py-8 px-0 md:py-12 pb-4 mx-auto flex flex-col items-center justify-center">
        <div className="h-auto w-full text-center relative">
          
          <div 
            className="bubble2 absolute -right-200 md:right-0 -top-12 md:-top-3 z-10"
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-easing="ease-in"
          >
            
            <div className="w-auto h-auto scale-75 md:scale-100">
              <StaticImage
                alt="katsana"
                src="../../../static/images/fuel/refuel-2.png"
                className="h-auto md:h-auto rounded-md w-[245px] md:w-full object-cover"
              />
            </div>
          </div>

          <StaticImage
            alt="katsana"
            src="../../../static/images/fuel/fueling.jpg"
            className="h-auto rounded-md w-full md:w-[750px]"
          />
        </div>

        <div className="px-0 sm:px-20 mt-6 sm:mt-8 w-full">
          <FuelFaqProbAccordion />
        </div>
      </div>


      <div className="flex flex-col sm:flex-row items-start justify-center w-full z-10 mt-2 sm:mt-0">        
        
        <div className="w-full sm:w-1/2 px-0 sm:px-6 mb-3 sm:mb-0">
          <div className="text-bluetext text-sm sm:text-base">
            <p className="my-3">
              <span className="font-semibold">KATSANA Fuel Management System</span> provides a comprehensive fuel tank monitoring feature for fleet operators.
            </p>
            <p className="my-3">
              Fuel spending is one of the significant expenses in the operations of a fleet. Inefficient use of fuel combined with wastage and theft can serious impact the profitablity of your fleet company.
            </p>
            <p className="my-3">
              Fuel tank monitoring system is the proven method to keep & manage fuel spending and consumption.
            </p>
            <p className="my-3">
              Say good bye to wasted fuel and guessing.
            </p>
          </div>
        </div>

        <div className="w-full sm:w-1/2 px-0 sm:px-6">
          <div className="text-bluetext text-sm sm:text-base">
            <h4 className="mt-3 mb-1 text-bluetext font-semibold">Solving your high fuel expense problem:</h4>
            <ul class="list-none">
              <li class="flex my-2 sm:my-3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6 text-primary mr-2">
                  <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                </svg>
                <p className="pt-0"><span className="text-primary font-semibold">Measure precise volume</span> in the fuel tank</p>
              </li>

              <li class="flex my-2 sm:my-3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6 text-primary mr-2">
                  <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                </svg>
                <p className="pt-0"><span className="text-primary font-semibold">Detect fuel siphoning</span> from fuel tank</p>
              </li>

              <li class="flex my-2 sm:my-3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6 text-primary mr-2">
                  <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                </svg>
                <p className="pt-0">Measure total <span className="text-primary font-semibold">refuel volume</span></p>
              </li>

              <li class="flex my-2 sm:my-3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6 text-primary mr-2">
                  <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                </svg>
                <p className="pt-0"><span className="text-primary font-semibold">Detect fuel theft </span> due to underfilling</p>
              </li>

              <li class="flex my-2 sm:my-3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6 text-primary mr-2">
                  <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                </svg>
                <p className="pt-0"><span className="text-primary font-semibold">Reduce up to 30% fuel expenses</span></p>
              </li>

              <li class="flex my-2 sm:my-3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6 text-primary mr-2">
                  <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                </svg>
                <p className="pt-0"><span className="text-primary font-semibold">Reduce excessive engine idling</span> events</p>
              </li>

              <li class="flex my-2 sm:my-3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6 text-primary mr-2">
                  <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                </svg>
                <p className="pt-0"><span className="text-primary font-semibold">Reduce speeding & fuel wasting</span> behaviors</p>
              </li>
              
            </ul>
          </div>
        </div>
        
      </div>

    </div>

    
  );
};

export default FuelExpenses;
