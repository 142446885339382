import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

function FuelMonitoring() {

  return (
    <div className="px-8 pt-6 pb-10 md:p-8 lg:p-8">

      <h2 className="max-w-3xl mx-auto mt-0 md:mt-2 text-2xl font-bold text-white  md:text-center md:text-4xl md:leading-[2.75rem]">
        Fuel Monitoring & Management solutions designed to help fleet operators <span className="text-primary">monitor, control & reduce</span> fuel spending
      </h2>

      <p className="max-w-2xl mx-auto mt-4 mb-7 md:mb-14 lg:mb-14 text-xs leading-5 md:text-center md:text-lg text-bluegray md:leading-8">
        High fuel expense is dragging your bottom line? KATSANA fuel solutions are 
        designed to help you identify causes of high fuel use, control and minimize fuel wastage, 
        and tackle possible fuel abuses such as theft.
      </p>

      <div className="flex flex-col items-center justify-center w-full z-10 -mt-4 sm:-mt-0">        
        
        <div className="flex flex-col sm:flex-row items-stretch justify-center">
          
          <div className="mt-4 sm:mt-0 w-full sm:w-1/3 max-h-80 mx-0 sm:mx-3 group ring-4 ring-transparent items-stretch flex flex-row sm:flex-col items-center rounded-xl relative shadow-2xl overflow-hidden duration-700 relative bg-white">
            <div className="flex-grow w-7/12 sm:w-full pt-2 pb-4 px-4 md:px-4 md:pt-4 md:pb-5 lg:px-6 lg:pt-5 lg:pb-6">
              <span className="text-sm sm:text-md text-bluetext">Fuel Level Sensors</span>
              <h3 className="font-semibold text-sm md:text-lg lg:text-xl text-bluetext leading-4 md:leading-6 lg:leading-6">
                Real-time Fuel Level Monitoring System for heavy vehicles
              </h3>
            </div>

            <div className="w-5/12 sm:w-full overflow-hidden flex-grow">
              <StaticImage
                alt="Fuel Level Sensor"
                src="../../../static/images/fuel/fb1.jpg"
                className="w-[130px] md:w-full object-fit h-30 sm:h-46 md:mt-1"
              />
            </div>
          </div>

          <div className="mt-4 sm:mt-0 w-full sm:w-1/3 max-h-80 mx-0 sm:mx-3 group ring-4 ring-transparent items-stretch flex flex-row sm:flex-col items-center rounded-xl relative shadow-2xl overflow-hidden duration-700 relative bg-white">
            <div className="flex-grow w-7/12 sm:w-full pt-2 pb-4 px-4 md:px-4 md:pt-4 md:pb-5 lg:px-6 lg:pt-5 lg:pb-6">
              <span className="text-sm sm:text-md text-bluetext">Driver Refueling App</span>
              <h3 className="font-semibold text-sm md:text-lg lg:text-xl text-bluetext leading-4 md:leading-6 lg:leading-6">
                Digitally capture refuel receipts, volume & cost
              </h3>
            </div>

            <div className="w-5/12 sm:w-full overflow-hidden flex-grow">
             <StaticImage
                alt="Fuel record app"
                src="../../../static/images/fuel/fb2.jpg"
                className="w-full object-fit h-full sm:h-52"
              />
            </div>
          </div>

          <div className="mt-4 sm:mt-0 w-full sm:w-1/3 max-h-80 mx-0 sm:mx-3 group ring-4 ring-transparent items-stretch flex flex-row sm:flex-col items-center rounded-xl relative shadow-2xl overflow-hidden duration-700 relative bg-white">
            <div className="flex-grow w-7/12 sm:w-full pt-2 pb-4 px-4 md:px-4 md:pt-4 md:pb-5 lg:px-6 lg:pt-5 lg:pb-6">
              <span className="text-sm sm:text-md text-bluetext">Fuel Dashboard & Reports</span>
              <h3 className="font-semibold text-sm md:text-lg lg:text-xl text-bluetext leading-4 md:leading-6 lg:leading-6">
                Identify sources of fuel wastage & leakage
              </h3>
            </div>

             <div className="w-5/12 sm:w-full overflow-hidden flex-grow items-center flex">
              <StaticImage
                alt="Fuel utilisation report"
                src="../../../static/images/fuel/fb3.jpg"
                className="w-full object-fill h-28 md:h-full md:h-auto relative top-0 md:-top-5"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row items-stretch justify-center mt:0 sm:mt-6">
          <div className="mt-4 sm:mt-0 w-full sm:w-1/3 max-h-80 mx-0 sm:mx-3 group ring-4 ring-transparent items-stretch flex flex-row sm:flex-col items-center rounded-xl relative shadow-2xl overflow-hidden duration-700 relative bg-white">
            <div className="flex-grow w-7/12 sm:w-full pt-2 pb-4 px-4 md:px-4 md:pt-4 md:pb-5 lg:px-6 lg:pt-5 lg:pb-6">
              <span className="text-sm sm:text-md text-bluetext">Vehicle Access Control</span>
              <h3 className="font-semibold text-sm md:text-lg lg:text-xl text-bluetext leading-4 md:leading-6 lg:leading-6">
                Driver Identification via NRIC, Bluetooth & RFID to control vehicle access
              </h3>
            </div>

             <div className="w-5/12 sm:w-full overflow-hidden relative flex-grow">
              <StaticImage
                alt="Driver Identification sensor"
                src="../../../static/images/fuel/fb4.jpg"
                className="w-full md:w-[310px] object-fit h-full md:h-[208px] relative mr-0 md:-right-3"
              />
            </div>
          </div>

          <div className="mt-4 sm:mt-0 w-full sm:w-1/3 max-h-80 mx-0 sm:mx-3 group ring-4 ring-transparent items-stretch flex flex-row sm:flex-col items-center rounded-xl relative shadow-2xl overflow-hidden duration-700 relative bg-white">
            <div className="flex-grow w-7/12 sm:w-full pt-2 pb-4 px-4 md:px-4 md:pt-4 md:pb-5 lg:px-6 lg:pt-5 lg:pb-6">
              <span className="text-sm sm:text-md text-bluetext">Vehicle Usage Record</span>
              <h3 className="font-semibold text-sm md:text-lg lg:text-xl text-bluetext leading-4 md:leading-6 lg:leading-6">
                Automate vehicle usage record-keeping through Digital Driver Logbook
              </h3>
            </div>

             <div className="w-5/12 sm:w-full overflow-hidden">
              <StaticImage
                alt="Digital Driver Logbook"
                src="../../../static/images/fuel/driver-booking-app.png"
                className="w-full object-fill h-[185px] md:h-[365px] relative md:-left-3 md:-top-7 -mb-16 -ml-4 md:ml-0 md:mb-0"
              />
            </div>
          </div>
        </div>

      </div>

    </div>

    
  );
};

export default FuelMonitoring;
