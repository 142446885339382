import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

function CompaniesMobile() {

  return (
    // <div className="grid w-full max-w-xl grid-cols-6 gap-4 px-8 mx-auto md:gap-6 md:my-8">
    <div className="flex flex-col items-center justify-center py-8 md:py-10 lg:py-12">

      <div className="flex lg:w-full xl:max-w-[730px] items-center justify-center">
        <p className="pb-2 text-xs text-center md:text-base text-bluegray w-8/12">
          Trusted by leading fleet operators across South East Asia
        </p>
      </div>

      <div className="flex flex-col items-center justify-center">

        <div className="flex px-4 py-4 lg:w-full xl:max-w-[820px] z-10 items-center justify-center -mb-3">
          <div className="px-4 flex items-center justify-center">
            <StaticImage
              alt="katsana"
              src="../../static/images/logos/companies/updates/gentari.png"
              className="w-full object-contain w-full"
              width={72}
            />
          </div>

          <div className="px-4 flex items-center justify-center">
            <StaticImage
              alt="katsana"
              src="../../static/images/logos/companies/updates/sunway.png"
              className="w-full object-contain"
              objectFit="contain"
              width={110}
            />
          </div>

          <div className="px-4 flex items-center justify-center">
            <StaticImage
              alt="katsana"
              src="../../static/images/logos/companies/updates/aerodyne.png"
              className="w-full object-contain"
              objectFit="contain"
              width={145}
            />
          </div>

          <div className="px-4 flex items-center justify-center">
            <StaticImage
              alt="katsana"
              src="../../static/images/logos/companies/updates/ranhill.png"
              className="w-full object-contain"
              objectFit="contain"
              width={40}
            />
          </div>
        </div>

        <div className="flex px-4 py-4 lg:w-full xl:max-w-[820px] z-10 items-center justify-center -mb-3">

          <div className="px-4 flex items-center justify-center">
            <StaticImage
              alt="katsana"
              src="../../static/images/logos/companies/updates/pharmaniaga.png"
              className="w-full object-contain"
              objectFit="contain"
              width={133}
            />
          </div>

          <div className="px-4 flex items-center justify-center">
            <StaticImage
              alt="katsana"
              src="../../static/images/logos/companies/updates/air-liquide.png"
              className="w-full object-contain"
              objectFit="contain"
              width={133}
            />
          </div>

          <div className="px-4 flex items-center justify-center">
            <StaticImage
              alt="katsana"
              src="../../static/images/logos/companies/updates/avis.png"
              className="w-full object-contain"
              objectFit="contain"
              width={77}
            />
          </div>

          <div className="px-4 flex items-center justify-center">
            <StaticImage
              alt="katsana"
              src="../../static/images/logos/companies/updates/drb-hicom.png"
              className="w-full object-contain"
              objectFit="contain"
              width={135}
            />
          </div>
        </div>

        <div className="flex px-4 py-4 lg:w-full xl:max-w-[820px] z-10 items-center justify-center -mb-3">
          <div className="px-4 flex items-center justify-center">
            <StaticImage
              alt="katsana"
              src="../../static/images/logos/companies/updates/sesb.png"
              className="w-full object-contain"
              objectFit="contain"
              width={32}
            />
          </div>

          <div className="px-4 flex items-center justify-center">
            <StaticImage
              alt="katsana"
              src="../../static/images/logos/companies/updates/simedarby.png"
              className="w-full object-contain"
              objectFit="contain"
              width={134}
            />
          </div>

          <div className="px-4 flex items-center justify-center">
            <StaticImage
              alt="katsana"
              src="../../static/images/logos/companies/updates/uthm.png"
              className="w-full object-contain"
              objectFit="contain"
              width={100}
            />
          </div>

          <div className="px-4 flex items-center justify-center">
            <StaticImage
              alt="katsana"
              src="../../static/images/logos/companies/updates/litrak.png"
              className="w-full object-contain"
              objectFit="contain"
              width={141}
            />
          </div>
        </div>


      </div>

    </div>

    
  );
};

export default CompaniesMobile;
