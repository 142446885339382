import * as React from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import "../../styles/accordion.css";

const FuelFaqProbAccordion = () => {
  return (
    <div className="block rounded-2xl sm:px-6 w-full">
      <h2 className="text-blueone text-2xl sm:text-4xl md:text-center font-bold mb-4 md:mb-4">Frequently Asked Questions about Fuel Monitoring System</h2>
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-lg md:text-xl sm:text-md font-semibold text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">1. What is the purpose of fuel monitoring system?</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-md md:text-lg border-slate-400 border-b">
              <p>
                Fleet operators install fuel monitoring system onto their vehicles to effectively manage & control fuel usage, reduce fuel costs due to inefficient driving & theft, improve operational efficiency and more recently to meet regulatory compliance (carbon emission control & calculation). 
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-lg md:text-xl sm:text-md font-semibold text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">2. How do fleet operators monitor fuel consumption?</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-md md:text-lg border-slate-400 border-b">
              <p>
                Fleet operators may monitor fuel consumption by installing fuel level sensors into fuel tanks or reading CANbus data from vehicles. 
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-lg md:text-xl sm:text-md font-semibold text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">3. What is fuel consumption monitoring in fleets?</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-md md:text-lg border-slate-400 border-b">
              <p>
                Fuel consumption monitoring records the monthly travel distance and total volume of fuel consumed by the vehicle each month. This will generate a L/100km value or total distance per liter that can be used to measure efficiency of the vehicle.
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-lg md:text-xl sm:text-md font-semibold text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">4. My driver submits a refuel receipt that shows 'Filled to full tank' - How can I verify this claim?</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-md md:text-lg border-slate-400 border-b">
              <p>
                Using a fuel monitoring system that integrates with Katsana vehicle tracking system, you can check the refuel and drain (or theft) events. This includes the volume of fuel filled, location and date/time, and total cost of refuel. 
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-lg md:text-xl sm:text-md font-semibold text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">5. Does fuel monitoring system alert me when there is fuel theft?</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-md md:text-lg border-slate-400 border-b">
              <p>
                An advanced fuel monitoring system (such as Katsana) will alert you when there is refuel or drain (theft) events. This includes the volume of fuel filled (or drained), location and date/time, and total cost of refuel. 
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-lg md:text-xl sm:text-md font-semibold text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">6. Can I view the fuel level and consumption in real-time?</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-md md:text-lg border-slate-400 border-b">
              <p>
                Yes definitely. When integrated with Katsana fleet management system, you will see a complete picture of the fuel levels for all your vehicles in the platform. This data is updated in real-time every few seconds.
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </div>
  );
};

export default FuelFaqProbAccordion;
