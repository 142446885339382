import * as React from "react";
// import { StaticImage } from "gatsby-plugin-image";
import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/solid";
// import { Link } from "gatsby";
import "../../styles/accordion.css";

const FuelFaqProbAccordion = () => {
  return (
    <div className="block rounded-2xl sm:px-6 w-full">
      <h3 className="text-primary text-md sm:text-lg font-medium">As a fleet operator, are you facing these problems?</h3>
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-sm sm:text-md font-medium text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">1. Fuel theft: Fuel receipts do not tally with actual volume in fuel tank.</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-sm border-slate-400 border-b">
              <p>
                Your diesel fleet card shows a transaction for 300 Litres of diesel, yet when you physically inspect the fuel tank a few hours later, the tank is only 20% filled. How could your truck consume all that fuel so fast? Is it possible that most of the 300L of diesel did not enter the fuel tank? How can you be sure?
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-sm sm:text-md font-medium text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">2. Fuel theft: Fuel cost is getting higher, but truck productivity & mileage remain the same.</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-sm border-slate-400 border-b">
              <p>
              Based on weekly and monthly report from your admin department, you can see that the overall spending for diesel has increased. 
              However it does not tally with the mileage record that you get from your fleet manager. 
              So where does the fuel go? 
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-sm sm:text-md font-medium text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">3. Fuel theft: Suspect that fuel is siphoned from fuel tank.</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-sm border-slate-400 border-b">
              <p>
              Your truck is parked overnight at the depot with full tank of fuel. 
              Yet when the driver inspects the truck in the morning, the fuel level is near empty. 
              The fuel has been siphoned from the tank. That is around RM1000 of fuel stolen.
              How do you prevent fuel theft, and catch the culprit?
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-sm sm:text-md font-medium text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">4. Wasted fuel: Fuel is wasted due to excessive engine idling.</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-sm border-slate-400 border-b">
              <p>
            Your truck is wasting fuel when it is idling for a prolonged period. 
            Your driver might be sleeping in it, or worse, simply park the truck without switching the engine off. 
            Your money is going down the drain every minute the engine runs. 
            How do you catch excessive idling events, how do you quantify in monetary value the loss of wasted fuel?
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-sm sm:text-md font-medium text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">5. Wasted fuel: Unable to precisely compare fuel usage and spending between vehicles & drivers.</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-sm border-slate-400 border-b">
              <p>
                You already know the total fuel cost for the entire fleet. 
                But do you know exactly the fuel cost for a specific vehicle or driver? 
                You wish for a system that make this data available immediately and with precision to help you manage your fuel budget and reduce wastage.
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-sm sm:text-md font-medium text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">6. Record-keeping: Unsure who exactly is using the vehicle and wasting fuel.</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-sm border-slate-400 border-b">
              <p>
                Your trucks are driven by ghosts, it seems. 
                You have no idea who exactly is using a particular truck at a specific time. 
                Your logbook does not show any relevant entry. 
                You wish for a system that help you manage vehicle use, and log usage detail to ensure there is no abuse nor additional costs to the company.
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-blueone border-b-0" : "text-bluetext"
              } flex justify-between w-full py-4 items-center text-sm sm:text-md font-medium text-left focus:outline-none border-b border-slate-400 hover:text-blueone`}
            >
              <span className="w-11/12 sm:w-full">7. Record-keeping: No digital systems to keep track of fuel receipts & driver claims.</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-blueone" : ""
                } w-5 h-5 text-bluetext`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-bluetext pb-4 text-sm border-slate-400 border-b">
              <p>
                You have many trucks in your fleet. 
                All fuel records, vehicle mileage records and operation records are written on papers, making it hard to digest and evaluate the financial health of your business. 
                You wish for a system that help you analyse spending across vehicles and drivers, enabling you to save cost.
              </p>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

    </div>
  );
};

export default FuelFaqProbAccordion;
